var render = function render(){
  var _vm$paymentDetail, _vm$paymentDetail$sch, _vm$paymentDetail2, _vm$paymentDetail3, _vm$paymentDetail3$ap, _vm$paymentDetail4, _vm$paymentDetail4$ap, _vm$paymentDetail5, _vm$paymentDetail5$ap, _vm$paymentDetail6, _vm$paymentDetail6$ap, _vm$paymentDetail7, _vm$paymentDetail7$ap, _vm$paymentDetail8, _vm$paymentDetail8$ap, _vm$paymentDetail9, _vm$paymentDetail9$ap, _vm$paymentDetail10, _vm$paymentDetail10$a, _vm$paymentDetail11, _vm$paymentDetail11$a, _vm$paymentDetail12, _vm$paymentDetail12$a, _vm$paymentDetail13, _vm$paymentDetail13$a, _vm$paymentDetail14, _vm$paymentDetail14$a, _vm$paymentDetail15, _vm$paymentDetail15$a, _vm$paymentDetail16, _vm$paymentDetail16$a, _vm$paymentDetail17, _vm$paymentDetail17$a, _vm$paymentDetail18, _vm$paymentDetail18$a, _vm$paymentDetail19, _vm$paymentDetail19$s, _vm$paymentDetail20, _vm$paymentDetail20$s, _vm$paymentDetail21, _vm$paymentDetail21$s, _vm$paymentDetail22, _vm$paymentDetail22$s, _vm$paymentDetail23, _vm$paymentDetail23$s, _vm$paymentDetail24, _vm$paymentDetail25, _vm$paymentDetail26;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "payment-detail"
  }, [_c('div', {
    staticClass: "flex items-center justify-between mb-6 mdmax:flex-col mdmax:gap-4"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mdmax:text-lg"
  }, [_vm._v(_vm._s(_vm.paymentDetail.id) + " / " + _vm._s((_vm$paymentDetail = _vm.paymentDetail) === null || _vm$paymentDetail === void 0 ? void 0 : (_vm$paymentDetail$sch = _vm$paymentDetail.schedule_info) === null || _vm$paymentDetail$sch === void 0 ? void 0 : _vm$paymentDetail$sch.booking_code))]), _c('div', {
    staticClass: "flex gap-2"
  }, [_c('LinkButton', {
    attrs: {
      "buttonText": "Kembali",
      "url": "/payment",
      "type": "secondary"
    }
  }), ((_vm$paymentDetail2 = _vm.paymentDetail) === null || _vm$paymentDetail2 === void 0 ? void 0 : _vm$paymentDetail2.status) !== 'settlement' ? _c('Button', {
    attrs: {
      "buttonText": "Set Lunas",
      "type": "primary",
      "icon": function () {
        return import( /* webpackChunkName: 'icon' */'@/components/Icons/CheckMark');
      }
    },
    on: {
      "action": function ($event) {
        return _vm.update();
      }
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "bg-white"
  }, [_c('div', {
    staticClass: "flex items-stretch shadow-border-b mdmax:flex-col"
  }, [_c('div', {
    staticClass: "w-3/5 shadow-border-r p-4 mdmax:shadow-none mdmax:w-full"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8 mdmax:text-base"
  }, [_vm._v("Data Diri")]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nama")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail3 = _vm.paymentDetail) === null || _vm$paymentDetail3 === void 0 ? void 0 : (_vm$paymentDetail3$ap = _vm$paymentDetail3.applicant_info) === null || _vm$paymentDetail3$ap === void 0 ? void 0 : _vm$paymentDetail3$ap.name))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Alamat Email")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail4 = _vm.paymentDetail) === null || _vm$paymentDetail4 === void 0 ? void 0 : (_vm$paymentDetail4$ap = _vm$paymentDetail4.applicant_info) === null || _vm$paymentDetail4$ap === void 0 ? void 0 : _vm$paymentDetail4$ap.email))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nomor KTP")]), _c('p', {
    staticClass: "text-lg mdmax:text-base mdmax:break-all"
  }, [_vm._v(_vm._s((_vm$paymentDetail5 = _vm.paymentDetail) === null || _vm$paymentDetail5 === void 0 ? void 0 : (_vm$paymentDetail5$ap = _vm$paymentDetail5.applicant_info) === null || _vm$paymentDetail5$ap === void 0 ? void 0 : _vm$paymentDetail5$ap.id_card_no))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nomor Handphone")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail6 = _vm.paymentDetail) === null || _vm$paymentDetail6 === void 0 ? void 0 : (_vm$paymentDetail6$ap = _vm$paymentDetail6.applicant_info) === null || _vm$paymentDetail6$ap === void 0 ? void 0 : _vm$paymentDetail6$ap.phone_no))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tempat Lahir")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail7 = _vm.paymentDetail) === null || _vm$paymentDetail7 === void 0 ? void 0 : (_vm$paymentDetail7$ap = _vm$paymentDetail7.applicant_info) === null || _vm$paymentDetail7$ap === void 0 ? void 0 : _vm$paymentDetail7$ap.pob))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Alamat rumah")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail8 = _vm.paymentDetail) === null || _vm$paymentDetail8 === void 0 ? void 0 : (_vm$paymentDetail8$ap = _vm$paymentDetail8.applicant_info) === null || _vm$paymentDetail8$ap === void 0 ? void 0 : _vm$paymentDetail8$ap.address))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tanggal Lahir")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(_vm._f("formatDate")((_vm$paymentDetail9 = _vm.paymentDetail) === null || _vm$paymentDetail9 === void 0 ? void 0 : (_vm$paymentDetail9$ap = _vm$paymentDetail9.applicant_info) === null || _vm$paymentDetail9$ap === void 0 ? void 0 : _vm$paymentDetail9$ap.dob)))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("RT/RW")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail10 = _vm.paymentDetail) === null || _vm$paymentDetail10 === void 0 ? void 0 : (_vm$paymentDetail10$a = _vm$paymentDetail10.applicant_info) === null || _vm$paymentDetail10$a === void 0 ? void 0 : _vm$paymentDetail10$a.rtrw))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Jenis Kelamin")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(((_vm$paymentDetail11 = _vm.paymentDetail) === null || _vm$paymentDetail11 === void 0 ? void 0 : (_vm$paymentDetail11$a = _vm$paymentDetail11.applicant_info) === null || _vm$paymentDetail11$a === void 0 ? void 0 : _vm$paymentDetail11$a.gender) === 'M' ? 'Laki-laki' : 'Perempuan'))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kel / Desa")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail12 = _vm.paymentDetail) === null || _vm$paymentDetail12 === void 0 ? void 0 : (_vm$paymentDetail12$a = _vm$paymentDetail12.applicant_info) === null || _vm$paymentDetail12$a === void 0 ? void 0 : _vm$paymentDetail12$a.village))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Golongan Darah")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail13 = _vm.paymentDetail) === null || _vm$paymentDetail13 === void 0 ? void 0 : (_vm$paymentDetail13$a = _vm$paymentDetail13.applicant_info) === null || _vm$paymentDetail13$a === void 0 ? void 0 : _vm$paymentDetail13$a.blood_type))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kecamatan")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail14 = _vm.paymentDetail) === null || _vm$paymentDetail14 === void 0 ? void 0 : (_vm$paymentDetail14$a = _vm$paymentDetail14.applicant_info) === null || _vm$paymentDetail14$a === void 0 ? void 0 : _vm$paymentDetail14$a.subdistrict))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Pekerjaan")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail15 = _vm.paymentDetail) === null || _vm$paymentDetail15 === void 0 ? void 0 : (_vm$paymentDetail15$a = _vm$paymentDetail15.applicant_info) === null || _vm$paymentDetail15$a === void 0 ? void 0 : _vm$paymentDetail15$a.job_type))])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kode Pos")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail16 = _vm.paymentDetail) === null || _vm$paymentDetail16 === void 0 ? void 0 : (_vm$paymentDetail16$a = _vm$paymentDetail16.applicant_info) === null || _vm$paymentDetail16$a === void 0 ? void 0 : _vm$paymentDetail16$a.postal_code))])])])]), _c('div', {
    staticClass: "w-2/5 p-4 mdmax:w-full"
  }, [_c('div', {
    staticClass: "mb-8"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Foto Selfie")]), _c('img', {
    staticClass: "mdmax:w-full max-h-44",
    attrs: {
      "src": (_vm$paymentDetail17 = _vm.paymentDetail) === null || _vm$paymentDetail17 === void 0 ? void 0 : (_vm$paymentDetail17$a = _vm$paymentDetail17.applicant_info) === null || _vm$paymentDetail17$a === void 0 ? void 0 : _vm$paymentDetail17$a.selfie_pict
    }
  })]), _c('div', {
    staticClass: "mb-8"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Foto KTP")]), _c('img', {
    staticClass: "mdmax:w-full max-h-44",
    attrs: {
      "src": (_vm$paymentDetail18 = _vm.paymentDetail) === null || _vm$paymentDetail18 === void 0 ? void 0 : (_vm$paymentDetail18$a = _vm$paymentDetail18.applicant_info) === null || _vm$paymentDetail18$a === void 0 ? void 0 : _vm$paymentDetail18$a.selfie_id_pict
    }
  })])])]), _c('div', {
    staticClass: "p-4"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Jadwal booking test")]), _c('div', {
    staticClass: "flex gap-6 mdmax:flex-col"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Wilayah")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail19 = _vm.paymentDetail) === null || _vm$paymentDetail19 === void 0 ? void 0 : (_vm$paymentDetail19$s = _vm$paymentDetail19.schedule_info) === null || _vm$paymentDetail19$s === void 0 ? void 0 : _vm$paymentDetail19$s.area_display))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6 mdmax:mb-0"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Lokasi Test")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail20 = _vm.paymentDetail) === null || _vm$paymentDetail20 === void 0 ? void 0 : (_vm$paymentDetail20$s = _vm$paymentDetail20.schedule_info) === null || _vm$paymentDetail20$s === void 0 ? void 0 : _vm$paymentDetail20$s.location_test_display))])])])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tanggal Booking Test")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(_vm._f("formatDate")((_vm$paymentDetail21 = _vm.paymentDetail) === null || _vm$paymentDetail21 === void 0 ? void 0 : (_vm$paymentDetail21$s = _vm$paymentDetail21.schedule_info) === null || _vm$paymentDetail21$s === void 0 ? void 0 : _vm$paymentDetail21$s.schedule_time)))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6 mdmax:mb-0"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Waktu Test")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(_vm._f("formatTime")((_vm$paymentDetail22 = _vm.paymentDetail) === null || _vm$paymentDetail22 === void 0 ? void 0 : (_vm$paymentDetail22$s = _vm$paymentDetail22.schedule_info) === null || _vm$paymentDetail22$s === void 0 ? void 0 : _vm$paymentDetail22$s.schedule_time)) + " WIB")])])])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Kode Booking ")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail23 = _vm.paymentDetail) === null || _vm$paymentDetail23 === void 0 ? void 0 : (_vm$paymentDetail23$s = _vm$paymentDetail23.schedule_info) === null || _vm$paymentDetail23$s === void 0 ? void 0 : _vm$paymentDetail23$s.booking_code))])])]), _c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6 mdmax:mb-0"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Status Pembayaran")]), _c('span', {
    staticClass: "inline-block text-sm font-medium px-2 py-1 rounded-md",
    class: [((_vm$paymentDetail24 = _vm.paymentDetail) === null || _vm$paymentDetail24 === void 0 ? void 0 : _vm$paymentDetail24.status) === 'settlement' ? 'bg-green-light text-green' : 'bg-yellow-light text-system-warning']
  }, [_vm._v(" " + _vm._s((_vm$paymentDetail25 = _vm.paymentDetail) === null || _vm$paymentDetail25 === void 0 ? void 0 : _vm$paymentDetail25.status_display) + " ")])])])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('div', {
    staticClass: "flex items-start mb-4 mdmax:gap-6"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Metode Pembayaran")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$paymentDetail26 = _vm.paymentDetail) === null || _vm$paymentDetail26 === void 0 ? void 0 : _vm$paymentDetail26.payment_method_display))])])])])])])]), _c('Modal', {
    attrs: {
      "modalVisible": _vm.visibleConfirmationModal,
      "width": "600px",
      "id": "confirmation-modal"
    },
    on: {
      "close": function ($event) {
        return _vm.toggleConfirmation();
      }
    }
  }, [_c('template', {
    slot: "modal-content"
  }, [_c('div', {
    staticClass: "flex items-center justify-start gap-6 mb-6"
  }, [_c('span', {
    staticClass: "flex-shrink-0"
  }, [_c('Alert', {
    attrs: {
      "color": "neutral-700"
    }
  })], 1), _c('div', {
    staticClass: "flex-col flex gap-2 items-start"
  }, [_c('p', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v("Anda Yakin?")]), _c('p', {
    staticClass: "text-sm text-neutral-500 text-left"
  }, [_vm._v("Apakah Anda yakin akan mengubah status pembayaran menjadi \""), _c('strong', [_vm._v("Pembayaran Diterima")]), _vm._v("\"?")])])]), _c('div', {
    staticClass: "flex justify-end gap-4"
  }, [_c('Button', {
    attrs: {
      "buttonText": "Tidak",
      "type": "secondary"
    },
    on: {
      "action": function ($event) {
        return _vm.toggleConfirmation();
      }
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Ya",
      "type": "primary"
    },
    on: {
      "action": function ($event) {
        return _vm.confirmEdit();
      }
    }
  })], 1)])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }